import { message, Modal, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/componentsUI/Button';
import constants from '../../../flux/constants';
import AuthService from '../../../utils/AuthService';

const Auth = new AuthService();

export default function OrderActions({ compra, functions }) {
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    if (Auth.getToken()) {
      setProfile(Auth.getProfile())
    }
  }, [])

  const openToggle = (name, status, messages) => {
    if (compra.compra_estado_id != constants.COMPRA_TARJETA_FALTA_PAGAR) {
      functions.toggleModal(name, status)
    } else {
      message.error(messages);

    }

  }
  return (
    <>
      <div className=''>
        {compra.compra_estado !== "completado" && <Button type={'success'} labelText='Completar pedido' onPress={() => openToggle('showCompleteOrder', true, 'No puede completar pedido con tarjeta en estado "falta de pago"')} />}
        <div className='Card'>
          {compra.compra_estado !== "completado" && compra.opciones.puede_delegar && <Button type={'outline-primary'} labelText='Delegar compra' onPress={() => functions.toggleModal('delegarCompra', true)} />}
          {compra.compra_estado !== "completado" && compra.compra_estado !== "completado" && <Button type={'outline-primary'} labelText='Colectar' onPress={() => functions.resolveByCollectors()} />}
          {compra.compra_estado !== "completado" && compra.opciones.puede_editar_productos && <Button type={'outline-primary'} labelText='Editar productos' onPress={() => openToggle('editarCompra', true, 'No puede editar pedido con tarjeta en estado "falta de pago"')} />}
          {compra.compra_estado !== "completado" && compra.opciones.puede_reprogramar && <Button type={'outline-primary'} labelText='Reprogramar' onPress={() => functions.toggleModal('reprogramarCompra', true)} />}
          {compra.compra_estado !== "completado" && compra.opciones.puede_cambiar_domicilio && <Button type={'outline-primary'} labelText='Cambiar Domicilio' onPress={() => functions.toggleModal('cambiaDomi', true)} />}

          {compra.compra_estado !== "completado" && compra.opciones.puede_split && <Button type={'outline-primary'} labelText='Split Compra' onPress={() => functions.toggleModal('splitCompra', true)} />}
          {compra.compra_estado !== "completado" && <Button type={'outline-primary'} labelText='Solucionar' onPress={() => functions.toggleModal('solucionarPedidosRojos', true)} />}
          <Button type={'outline-primary'} labelText='Ingresar notas internas' onPress={() => functions.toggleModal('showFollowOrder', true)} />

          {compra.compra_estado !== "completado" && compra.treggo_id != undefined ?
            <Button type={'outline-danger'} labelText='Cancelar Logistica Terciarizada' onPress={() => functions.logisticType(0)} />
            :
            <>

              {
                compra.compra_estado !== "completado" &&
                <Row>
                  <Col>
                    <Button type={'outline-primary'} labelText='Logistica Colecta' onPress={() => functions.logisticType('colecta')} />
                  </Col>
                  <Col>
                    <Button type={'outline-primary'} labelText='Logistica On Demand' onPress={() => functions.logisticType('on demand')} />
                  </Col>
                </Row>
              }
            </>

          }
          {compra.compra_estado !== "completado" &&
            <Button type={'outline-primary'} labelText='Logistica Externa' onPress={() => functions.externalLogistic()} />

          }

          {compra.tag_last_contact_movment === 148 && compra.compra_estado !== "completado" ?
            <Button type={'outline-primary'} labelText='Contactado por CS' onPress={() => functions.toggleModal('showContactedByCs', true)} />
            :
            <>
              {compra.compra_estado !== "completado" && <Button type={'outline-primary'} labelText='A Contactar CS' onPress={() => functions.toggleModal('showToContactByCs', true)} />
              }
            </>
          }

          {compra.compra_estado !== "completado" && <Button type={'outline-danger'} labelText='Intervenir petshop' onPress={() => functions.toggleModal('intervencion', true)} />}
          {compra.compra_estado !== "completado" && compra.opciones.puede_oportunidad && <Button type={'outline-danger'} labelText='Poner en oportunidad' onPress={() => functions.oportunityOrder()} />}
          {compra.compra_estado !== "completado" && compra.opciones.puede_oportunidad && <Button type={'outline-danger'} labelText='Oportunidad desde cero' onPress={() => functions.oportunityOrder(1)} />}
          {compra.compra_estado !== "completado" && compra.opciones.puede_cancelar && <Button type={'outline-danger'} labelText='Reembolso - Cancelar' onPress={() => functions.toggleModal('reembolso', true)} />}
          {compra.compra_estado !== "completado" && compra.opciones.puede_poner_problema && <Button type={'outline-danger'} labelText='Pasar a problemas' onPress={() => functions.toggleModal('showProblemOrder', true)} />}

        </div>
        {
          (compra.compra_estado !== "completado" && (compra.opciones.puede_pago_manual || compra.opciones.puede_datos_mercado_pago)) &&
          <div className='Card'>
            {<Button type={'outline-primary'} labelText='Adjuntar Digitales' onPress={() => functions.toggleModal('adjuntaModal', true)} />}
            {<Button type={'outline-primary'} labelText='Notas al petshop' onPress={() => functions.toggleModal('showNotes', true)} />}
            {<Button type={'outline-danger'} labelText='Pago manual' onPress={() => functions.toggleModal('manualPaid', true)} />}
            {<Button type={'outline-danger'} labelText='Generar Link de pago' onPress={() => functions.toggleModal('generateLink', true)} />}
            {/* {compra.opciones.puede_datos_mercado_pago && <Button type={'outline-danger'} labelText='Datos cliente MP' onPress={() => functions.toggleInfoClientPayment()} />} */}
          </div>
        }
        <div className='Card'>
          {profile?.rol == 'admin' ?
            <Button
              type={'outline-primary'}
              labelText='PichoPesos'
              onPress={() => functions.toggleModal('referModal', true)}
            />
            :
            <Button
              type={'outline-primary'}
              labelText='PichoPesos Mala Experiencia'
              onPress={() => {

                if (compra.opciones.picho_pesos_cargados) {
                  return Modal.info({
                    title: 'Importante',
                    content: 'Para esta compra ya fueron cargados picho pesos anteriormente'
                  })
                }

                if (!compra.opciones.puede_generar_mala_experiencia) {
                  return Modal.warning({
                    title: 'Importante',
                    content: 'Para generar una mala experiencia, el pedido debe haber pasado por un proceso de reprogramación o de editar productos'
                  })
                }

                return functions.toggleModal('referModalBadExperience', true)

              }}
            />
          }
        </div>
        {compra.opciones.puede_abrir_reclamo
          ? <>
            <div className='Card'>
              <Button type={'outline-primary'} labelText='Abrir Reclamo / Solicitud' onPress={() => functions.toggleModal('showWizard', true)} />
            </div>
          </>
          : null
        }
        <div className='Card'>
          <Button type={'outline-primary'} labelText='Abrir Reclamo (v2.0)' onPress={() => functions.toggleModal('showNewWizard', true)} />
        </div>

      </div>
    </>

  )
}
